<template>
    <div class="SelfInCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.groupCode"
                                @change="handleChangeGroup"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :label="group.name"
                                    :key="group.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" @change="tableData = []" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptsInGroup"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker v-model="form.createDate" format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSelectMembers" size="small">选择会员</el-button>
            <el-button type="primary" @click="handleSave" size="small" :disabled="tableData.length == 0 ? true : false"
                >保存</el-button
            >
        </el-card>
        <pickUpMembers ref="pickUpMembers" @ok="handleSelectMembersOk" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="pickUpMembersTable"
                border
                ref="pickUpMembersTable"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
            >
                <el-table-column width="60" type="selection" />
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="name" label="会员名称" width="180" />
                <el-table-column prop="mobile" label="手机号" width="120" />
                <el-table-column prop="sex" label="性别" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.sex | sex }}</span></template
                    >
                </el-table-column>
                <el-table-column prop="createTime" label="注册时间" width="140" />
                <el-table-column prop="regChannel" label="注册渠道" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.regChannel | regChannel }}</span></template
                    >
                </el-table-column>
                <el-table-column label="操作" min-width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import Util from '../../../../js/Util';
import pickUpMembers from '../../../components/pickUpMembers.vue';

export default {
    name: 'MemberCreate',
    components: { pickUpMembers },
    data() {
        return {
            form: {
                createDate: new Date(),
                creator: this.$store.state.session.name,
                groupCode: '',
                deptCode: '',
            },
            tableData: [],
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryMembers: '/Members/Members/pageExtend',
                save: '/reserve/member/createExtend',
                queryDeptByGroup: '/system/dept/deptTree',
            },
            loading: false,
            meta: {
                groups: [],
                deptsInGroup: {},
            },
            current: {
                typeIdx: 2,
            },
            queryData: {
                page: 1,
                search: '',
                deptCode: '',
                limit: Util.Limit,
            },
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, this.url.queryGroups, (rst) => {
            this.meta.groups = rst;
            this.loadingDeptGroupFlag = false;
            this.handleChangeGroup(' ');
        });
    },
    filters: {
        regChannel(regChannel) {
            if (typeof regChannel == 'undefined') {
                return '';
            }
            if (regChannel == '8') {
                return '后台新建';
            }
            if (regChannel == '10') {
                return '会员初始化';
            }
        },
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            } else if (sex == '1') {
                return '女';
            }
        },
    },
    methods: {
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            if (_this.meta.deptsInGroup[value]) {
                return _this.meta.deptsInGroup[value];
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.deptsInGroup = rst;
            });

            this.tableData = [];
        },
        handleSelectMembers() {
            if (this.form.deptCode == '') {
                this.$message.error('请选择机构');
            } else {
                this.$refs.pickUpMembers.show();
            }
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
        handleSelectMembersOk(data) {
            let _a = this.tableData;
            data.forEach((v) => {
                _a.push(v);
            });
            _a = this.duplicate(_a, 'code');
            this.tableData = _a;
        },
        duplicate(arr, type) {
            if (arr.length == 0) {
                return arr;
            } else {
                if (type) {
                    const obj = {};
                    const newArr = arr.reduce((cur, next) => {
                        obj[next.code] ? '' : (obj[next.code] = true && cur.push(next));
                        return cur;
                    }, []);
                    return newArr;
                } else {
                    return Array.from(new Set(arr));
                }
            }
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page2, _params);
        },
        handleSave() {
            const _params = {
                memberCode: this.tableData.map((d) => d.code),
                deptCode: this.form.deptCode,
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
